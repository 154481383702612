import React, { useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    "&:not(:last-child)": {
      margin: "0 4px 1rem 0",
    },
  },
}));

const ChipsInput = ({ modalData, modal, data, setData }) => {
  const classes = useStyles();

  //   for modal i.e edit form

  const dataCopy = [];

  if (modal && modalData && dataCopy.length !== modalData.task.length) {
    for (const item of modalData.task) {
      dataCopy.push(item.taskName);
    }
  }

  useEffect(() => {
    setData(dataCopy);
    // eslint-disable-next-line
  }, [dataCopy.length]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.target.value.length > 0) {
      e.preventDefault();
      setData([...data, e.target.value]);
      e.target.value = "";
    }
  };

  const handleTaskBlur = (e) => {
    if (e.target.value.length > 0) {
      setData([...data, e.target.value]);
      e.target.value = "";
    }
  };

  const handleDelete = (index) => {
    const updatedType = data;
    updatedType.splice(index, 1);
    setData([...updatedType]);
  };

  const renderChip = () => {
    return data.map((item, index) => (
      <Chip
        className={classes.chip}
        key={`chip-${index}`}
        label={item}
        onDelete={() => handleDelete(index)}
      />
    ));
  };

  return (
    <>
      <TextField
        id="formTask"
        label="Task"
        onKeyDown={handleKeyDown}
        onBlur={handleTaskBlur}
        variant="outlined"
        fullWidth
        color="primary"
      />
      {renderChip()}
    </>
  );
};

export default ChipsInput;
