import React from "react";
import AllProvider from "../Provider/AllProvider";

import Skeleton from "components/Skeleton";

const Dashboard = () => {
  return (
    <Skeleton page="root">
      <AllProvider home />
    </Skeleton>
  );
};

export default Dashboard;
