import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Nav, Accordion } from "react-bootstrap";
import { FaCircle, FaUserTie } from "react-icons/fa";
import { FiList, FiGrid } from "react-icons/fi";
import { FcTodoList } from "react-icons/fc";
import { IoIosGitBranch, IoIosBuild, IoIosHome } from "react-icons/io";
import { ReactComponent as Logo } from "images/logo.svg";
import { hideSideBar } from "components/SideBar/sideBarSlice";

import * as routes from "routes";

const theme = createMuiTheme();

theme.typography.h3 = {
  fontSize: "1rem !important",
  color: "rgba(255, 255, 255, .6)",
  fontWeight: "400 !important",
  cursor: "pointer",
  transition: "color .2s ease-in-out",
  borderBottom: "1px solid #4b545c",
  textDecoration: "none",
  padding: ".4rem 1.4rem 1.4rem 1.4rem",

  "&:hover": {
    color: "rgba(255, 255, 255, .95)",
  },
};

const useStyles = makeStyles({
  root: {
    background: "#343a40",
    boxShadow: "0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)",
    overflowY: "hidden",
    zIndex: 100,
    height: "inherit",
    minHeight: "100vh",
    position: "fixed",
    top: 0,
    width: "var(--width-sideBar)",
    transition: "transform .35s ease-in",

    "@media only screen and (max-width: 56.25em)": {
      transform: "translateX(-25rem)",
    },
  },
  link: {
    textDecoration: "none",
  },

  sidebar: {
    height: "calc(100% - (3.5rem + 1px))",
    overflowY: "auto",
    padding: "0 .5rem",
  },

  icon: {
    fontSize: ".8rem",
    marginLeft: ".6rem",
  },

  logo: {
    margin: "1rem 0 0 1rem",
  },

  active: {
    transform: "translateX(0)",
  },
});

const SideBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [activePath] = useState(window.location.pathname);
  const sideBarStatus = useSelector((state) => state.sideBar);

  const getActiveKey = () => {
    if (activePath.includes("ubcategor")) {
      return "1";
    } else if (activePath.includes("Categor")) {
      return "0";
    } else if (activePath.includes("Branch")) {
      return "2";
    } else if (activePath.includes("Service")) {
      return "3";
    } else if (activePath.includes("Home")) {
      return "5";
    } else if (activePath.includes("todo")) {
      return "6";
    } else {
      return "4";
    }
  };

  const handleClick = (e, path) => {
    history.push(path);
    e.stopPropagation();
    dispatch(hideSideBar());
  };

  return (
    <aside className={`${classes.root} ${sideBarStatus ? classes.active : ""}`}>
      <Link className={classes.link} to="/">
        <Logo className={classes.logo} />
        <ThemeProvider theme={theme}>
          <Typography variant="h3">Admin Dashboard</Typography>
        </ThemeProvider>
      </Link>

      <div className={classes.sidebar}>
        <Accordion defaultActiveKey={getActiveKey()}>
          <Accordion.Toggle as={Nav.Item} eventKey="4">
            <Nav.Link>
              <FaUserTie />
              Providers
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.ALL_PROVIDERS)}
                  active={activePath === routes.ALL_PROVIDERS ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  All Providers
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>

          <Accordion.Toggle as={Nav.Item} eventKey="0">
            <Nav.Link>
              <FiGrid />
              Category
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.ADD_CATEGORY)}
                  active={activePath === routes.ADD_CATEGORY ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  Add Category
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.VIEW_ALL_CATEGORIES)}
                  active={
                    activePath === routes.VIEW_ALL_CATEGORIES ? true : false
                  }
                >
                  <FaCircle className={classes.icon} />
                  View All Categories
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>
          <Accordion.Toggle as={Nav.Item} eventKey="1">
            <Nav.Link>
              <FiList />
              Subcategory
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.ADD_SUBCATEGORY)}
                  active={activePath === routes.ADD_SUBCATEGORY ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  Add Subcategory
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) =>
                    handleClick(e, routes.VIEW_ALL_SUB_CATEGORIES)
                  }
                  active={
                    activePath === routes.VIEW_ALL_SUB_CATEGORIES ? true : false
                  }
                >
                  <FaCircle className={classes.icon} />
                  View All Subcategories
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>
          <Accordion.Toggle as={Nav.Item} eventKey="2">
            <Nav.Link>
              <IoIosGitBranch />
              Branch
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.ADD_BRANCH)}
                  active={activePath === routes.ADD_BRANCH ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  Add Branch
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.VIEW_ALL_BRANCH)}
                  active={activePath === routes.VIEW_ALL_BRANCH ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  View All Branch
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>
          <Accordion.Toggle as={Nav.Item} eventKey="3">
            <Nav.Link>
              <IoIosBuild />
              Service
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.ADD_SERVICE)}
                  active={activePath === routes.ADD_SERVICE ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  Add Service
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.VIEW_ALL_SERVICE)}
                  active={activePath === routes.VIEW_ALL_SERVICE ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  View All Service
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>
          <Accordion.Toggle as={Nav.Item} eventKey="5">
            <Nav.Link>
              <IoIosHome />
              Home
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.ADD_HOME)}
                  active={activePath === routes.ADD_HOME ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  Add Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.VIEW_ALL_HOME)}
                  active={activePath === routes.VIEW_ALL_HOME ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  View All Home
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>

          <Accordion.Toggle as={Nav.Item} eventKey="6">
            <Nav.Link>
              <FcTodoList />
              Todos
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.ADD_TODOS)}
                  active={activePath === routes.ADD_TODOS ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  Add Todo
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, routes.VIEW_ALL_TODOS)}
                  active={activePath === routes.VIEW_ALL_TODOS ? true : false}
                >
                  <FaCircle className={classes.icon} />
                  View All Todos
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </aside>
  );
};

export default SideBar;
