import React from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import SideBar from "components/SideBar";
import TopBar from "components/TopBar";
import Info from "components/Info";
import BackBtn from "components/BackBtn";
import { hideSideBar } from "components/SideBar/sideBarSlice";

const useStyles = makeStyles({
  container: {
    padding: "1rem 2rem 1rem 20rem",
    margin: "4rem 0 0 0",
    width: "100%",

    "@media only screen and (max-width: 56.25em)": {
      padding: "1rem 2rem 1rem 1rem",
    },
  },
});

const Skeleton = ({ children, page }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(hideSideBar());
  };

  return (
    <div>
      <SideBar />
      <TopBar />

      <div onClick={handleClick} style={{ minHeight: "80vh" }}>
        <Grid className={classes.container} container spacing={4}>
          {page === "root" ? <Info /> : false}
          {page !== "root" ? <BackBtn /> : false}
          {children}
        </Grid>
      </div>
    </div>
  );
};

export default Skeleton;
