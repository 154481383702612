import axios from "axios";

const buildLocal = axios.create({
  baseURL: process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_DEVELOPMENT_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_API_KEY : process.env.REACT_APP_DEVELOPMENT_API_KEY,
    "Content-Type": "application/json",
  },
});
buildLocal.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default buildLocal;
