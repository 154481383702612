import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "features/Category/categorySlice";

const useProviderList = (start, limit) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategory([start, limit]));
    // eslint-disable-next-line
  }, [start, limit]);

  return useSelector((state) => Object.values(state.categories.list));
};

export default useProviderList;
