import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    marginBottom: "1rem",
    display: "flex",

    "@media only screen and (max-width: 56.25em)": {
      flexDirection: "column",
    },
  },
});

const DatePicker = ({
  handleDateToChange,
  handleDateFromChange,
  fromDate,
  toDate,
}) => {
  const handleDateFromChangeChild = (date) => {
    handleDateFromChange(date);
  };

  const handleDateToChangeChild = (date) => {
    handleDateToChange(date);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid style={{ marginRight: "1rem" }} container justify="space-around">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-from-inline"
            label="From"
            value={fromDate}
            onChange={handleDateFromChangeChild}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-to-inline"
            label="TO"
            value={toDate}
            onChange={handleDateToChangeChild}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;
