import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import { string, object, number } from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { ImageURL } from "utils";

import { hideModal } from "Modal/modalSlice";
import Skeleton from "components/Skeleton";
import {
  createCategory,
  updateCategory,
  clearMessage,
} from "features/Category/categorySlice";
import { encrypt } from "utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2, 0),
    },
  },
  modal: {
    maxHeight: "80vh",
    overflow: "auto",
  },
  chip: {
    "&:not(:last-child)": {
      margin: "0 4px 1rem 0",
    },
  },
  formControl: {
    margin: "1rem 0",
  },
  action: {
    padding: theme.spacing(2),

    "& > button": {
      padding: theme.spacing(1, 5),
    },
  },
  img: {
    width: "100%",
  },
}));

const AddCategory = ({ modal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [file, setFile] = useState();
  const [fileError, setFileError] = useState(false);

  const [file1, setFile1] = useState();
  const [fileError1, setFileError1] = useState(false);

  const loaderDisplay = useSelector((state) => state.categories.loading);
  const message = useSelector((state) => state.categories.message);
  const severity = useSelector((state) => state.categories.severity);

  const modalId = useSelector((state) => state.modal.id);
  const formModalData = useSelector((state) => state.categories.list[modalId]);

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);

      setTimeout(() => {
        dispatch(clearMessage());
      }, 4100);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    setOpen(false);
  }, []);

  const initialValues = {
    categoryName: modal && formModalData ? formModalData.categoryName : "",
    description:
      modal && formModalData.description ? formModalData.description : "",
    subType: modal && formModalData.subType ? formModalData.subType : 1,
  };

  const validationSchema = object().shape({
    categoryName: string().required("Required"),
    description: string().required("Required"),
    subType: number().required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    let encryptedData;

    if (modal && !file) {
      encryptedData = encrypt(
        JSON.stringify({
          ...values,
          image: formModalData.image,
        })
      );
    } else if (modal && !file1) {
      encryptedData = encrypt(
        JSON.stringify({
          ...values,
          homeImage: formModalData.homeImage,
        })
      );
    } else if (modal && !file && !file1) {
      encryptedData = encrypt(
        JSON.stringify({
          ...values,
          image: formModalData.image,
          homeImage: formModalData.homeImage,
        })
      );
    } else {
      encryptedData = encrypt(JSON.stringify({ ...values }));
    }

    formData.append("data", JSON.stringify(encryptedData));

    if (file) formData.append("image", file);
    if (file1) formData.append("homeImage", file1);

    if (modal) {
      dispatch(updateCategory([modalId, formData]));
      dispatch(hideModal());
    } else {
      dispatch(createCategory(formData));
      resetForm();
      document.querySelector("#formFile").value = "";
      document.querySelector("#formFile1").value = "";
    }
  };

  const btnStatus = (isValid, dirty) => {
    // checking if upload or edit

    if (!modal) {
      return !(isValid && dirty && !fileError && file);
    }
    return false;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const renderImage = (type) => {
    if (modal && formModalData.image) {
      const imageKey = type === "file" ? "image" : "homeImage";
      return (
        <img
          className={classes.img}
          src={`${ImageURL}${formModalData[imageKey]}`}
          alt={formModalData.categoryName}
        />
      );
    }
  };

  const handleFileChange = (event, type) => {
    const fileArray =
      event.currentTarget.files[0] &&
      event.currentTarget.files[0].name.split(".");

    const fileExt = fileArray && fileArray[fileArray && fileArray.length - 1];
    if (fileExt === "png" || fileExt === "jpg") {
      type === "file"
        ? setFile(event.currentTarget.files[0])
        : setFile1(event.currentTarget.files[0]);
      type === "file" ? setFileError(false) : setFileError1(false);
    } else {
      type === "file" ? setFileError(true) : setFileError1(true);
    }
  };

  const renderForm = () => {
    return (
      <Card
        variant="outlined"
        color="primary"
        className={`${classes.root} ${modal ? classes.modal : ""}`}
      >
        <CardHeader title="Add Category" />
        <div className={classes.form}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <CardContent>
                  <TextField
                    error={touched.categoryName && !!errors.categoryName}
                    name="categoryName"
                    id="formcategoryName"
                    label="Category Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.categoryName}
                    helperText={
                      touched.categoryName ? errors.categoryName : false
                    }
                    variant="outlined"
                    fullWidth
                    color="primary"
                  />

                  <TextField
                    error={touched.description && !!errors.description}
                    name="description"
                    id="formDescription"
                    label="Description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    helperText={
                      touched.description ? errors.description : false
                    }
                    variant="outlined"
                    fullWidth
                    color="primary"
                  />

                  <FormControl
                    variant="outlined"
                    fullWidth
                    color="primary"
                    className={classes.formControl}
                    error={touched.subType && !!errors.subType}
                  >
                    <InputLabel id="formSelect">Type</InputLabel>
                    <Select
                      name="subType"
                      labelId="formSelectLabel"
                      id="formSelect"
                      onChange={!modal ? handleChange : null}
                      onBlur={handleBlur}
                      value={values.subType}
                      label="Type"
                    >
                      <MenuItem value={1}>InDoor</MenuItem>
                      <MenuItem value={2}>OutDoor</MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched.subType ? errors.subType : false}
                    </FormHelperText>
                  </FormControl>

                  <TextField
                    id="formFile"
                    type="file"
                    error={fileError}
                    variant="outlined"
                    helperText={`Allowed format jpg and png ${
                      fileError ? "(invalid file format)" : ""
                    }`}
                    onChange={(e) => handleFileChange(e, "file")}
                    fullWidth
                    color="primary"
                  />
                  {renderImage("file")}

                  <TextField
                    id="formFile1"
                    type="file"
                    error={fileError1}
                    variant="outlined"
                    helperText={`Allowed format jpg and png ${
                      fileError1 ? "(invalid file format)" : ""
                    }`}
                    onChange={(e) => handleFileChange(e, "file1")}
                    fullWidth
                    color="primary"
                  />
                  {renderImage("file1")}
                </CardContent>
                <CardActions className={classes.action}>
                  <Button
                    disabled={btnStatus(isValid, dirty)}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </CardActions>
              </form>
            )}
          </Formik>
        </div>
      </Card>
    );
  };

  if (modal) {
    return renderForm();
  }

  return (
    <Skeleton>
      {renderForm()}
      <Snackbar open={open} autoHideDuration={3900} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || "error"}>
          <p className="message">{message}</p>
        </Alert>
      </Snackbar>
      {loaderDisplay ? <LinearProgress className="loader" /> : false}
    </Skeleton>
  );
};

export default AddCategory;
