import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  backBtn: {
    border: "none",
    backgroundColor: "transparent",
    marginBottom: "2.5rem",
    fontSize: "1.2rem",
    display: "flex",
    alignItems: "center",

    "& > svg": {
      fontSize: "1rem",
      marginTop: "1px",
      marginRight: "3px",
    },
  },
  btnContainer: {
    width: "100%",
  },
});

const BackBtn = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.btnContainer}>
      <button onClick={() => history.push("/")} className={classes.backBtn}>
        <IoIosArrowBack />
        <p>Back</p>
      </button>
    </div>
  );
};

export default BackBtn;
