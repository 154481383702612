import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  providerListVerfiedRequest,
  providerListRequest,
} from "customHooks/providerList/providerListSlice";
import { encrypt } from "utils";

const useProviderList = (start, limit, adminVerify) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (adminVerify !== undefined) {
      dispatch(
        providerListVerfiedRequest([
          start,
          limit,
          encrypt(JSON.stringify({ adminVerify })),
        ])
      );
    } else {
      dispatch(providerListRequest([start, limit]));
    }

    // eslint-disable-next-line
  }, [start, limit, adminVerify]);

  return useSelector((state) => Object.values(state.providerList));
};

export default useProviderList;
