import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import { string, object } from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import Skeleton from "components/Skeleton";
import { createTodos, clearMessage, updateTodo } from "./todoSlice";
import { hideModal } from "Modal/modalSlice";
import { encrypt } from "utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(2, 0),
    },
  },
  chip: {
    "&:not(:last-child)": {
      margin: "0 4px 1rem 0",
    },
  },
  formControl: {
    margin: "1rem 0",
    width: "100%",
  },
  action: {
    padding: theme.spacing(2),

    "& > button": {
      padding: theme.spacing(1, 5),
    },
  },
  img: {
    width: "100%",
  },
}));

const CreateTodo = ({ modal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const loaderDisplay = useSelector((state) => state.todos.loading);
  const message = useSelector((state) => state.todos.message);
  const severity = useSelector((state) => state.todos.severity);

  const modalId = useSelector((state) => state.modal.id);
  const formModalData = useSelector((state) => state.todos.list[modalId]);

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);

      setTimeout(() => {
        dispatch(clearMessage());
      }, 4100);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    setOpen(false);
  }, []);

  const initialValues = {
    description:
      modal && formModalData.description ? formModalData.description : "",
    heading: modal && formModalData.heading ? formModalData.heading : "",
    type: modal && formModalData.type ? formModalData.type : 1,
  };

  const validationSchema = object().shape({
    description: string().required("Required"),
    heading: string().required("Required"),
    type: string().required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    const encryptedData = encrypt(JSON.stringify(values));

    formData.append("data", JSON.stringify(encryptedData));

    if (modal) {
      dispatch(updateTodo([modalId, formData]));
      dispatch(hideModal());
    } else {
      dispatch(createTodos(formData));
      resetForm();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const btnStatus = (isValid, dirty) => {
    // checking if upload or edit

    if (!modal) {
      return !(isValid && dirty);
    }
    return false;
  };

  const renderForm = () => {
    return (
      <Card variant="outlined" color="primary" className={classes.root}>
        <CardHeader title={modal ? "Edit Todo" : "Add Todo"} />
        <div className={classes.form}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <CardContent>
                  <TextField
                    error={touched.heading && !!errors.heading}
                    name="heading"
                    id="heading"
                    label="Heading"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.heading}
                    helperText={touched.heading ? errors.heading : false}
                    variant="outlined"
                    fullWidth
                    color="primary"
                  />

                  <TextField
                    error={touched.description && !!errors.description}
                    name="description"
                    id="formDescription"
                    label="Description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    helperText={
                      touched.description ? errors.description : false
                    }
                    variant="outlined"
                    fullWidth
                    color="primary"
                  />

                  <FormControl
                    variant="outlined"
                    fullWidth
                    color="primary"
                    className={classes.formControl}
                  >
                    <InputLabel id="formSelect1">Type</InputLabel>
                    <Select
                      name="type"
                      onChange={handleChange}
                      error={touched.type && !!errors.type}
                      onBlur={handleBlur}
                      labelId="formSelect1"
                      id="formSelect1"
                      label="Type"
                      value={values.type}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </CardContent>
                <CardActions className={classes.action}>
                  <Button
                    disabled={btnStatus(isValid, dirty)}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </CardActions>
              </form>
            )}
          </Formik>
        </div>
      </Card>
    );
  };

  if (modal) {
    return renderForm();
  }

  return (
    <Skeleton>
      {renderForm()}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || "error"}>
          <p className="message">{message}</p>
        </Alert>
      </Snackbar>
      {loaderDisplay ? <LinearProgress className="loader" /> : false}
    </Skeleton>
  );
};

export default CreateTodo;
