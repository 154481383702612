import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import Skeleton from "components/Skeleton";
import {
  createService,
  updateService,
  clearMessage,
} from "features/Service/serviceSlice";
import { encrypt } from "utils";
import useBranchList from "customHooks/useBranchList";
import { hideModal } from "Modal/modalSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(2, 0),
    },
  },
  modal: {
    maxHeight: "80vh",
    overflow: "auto",
  },
  chip: {
    marginBottom: "qrem",

    "&:not(:last-child)": {
      marginRight: "4px",
    },
  },
  formControl: {
    margin: "1rem 0",
  },
  action: {
    padding: theme.spacing(2),

    "& > button": {
      padding: theme.spacing(1, 5),
    },
  },
}));

const AddService = ({ modal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [typeArray, setTypeArray] = useState([]);

  const loaderDisplay = useSelector((state) => state.service.loading);
  const message = useSelector((state) => state.service.message);
  const severity = useSelector((state) => state.service.severity);
  const modalId = useSelector((state) => state.modal.id);
  const [modalInputValue, setModalInputValue] = useState("");

  const branchList = useBranchList(-1, 0);

  const [select, setSelect] = useState("");

  const formModalData = useSelector((state) => state.service.list[modalId]);

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);

      setTimeout(() => {
        dispatch(clearMessage());
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    setOpen(false);
    if (modal) {
      setModalInputValue(formModalData && formModalData.serviceName);
      setSelect(formModalData && formModalData.branchId._id);
    }
    // eslint-disable-next-line
  }, []);

  const renderMenuItem = () => {
    return branchList.map((item) => {
      return (
        <MenuItem key={item._id} value={item._id}>
          {item.branchName}
        </MenuItem>
      );
    });
  };

  const handleDelete = (index) => {
    const updatedType = typeArray;
    updatedType.splice(index, 1);
    setTypeArray([...updatedType]);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.target.value.length > 0 && !modal) {
      e.preventDefault();
      setTypeArray([...typeArray, e.target.value]);
      setModalInputValue("");
    }
  };

  const handleTaskBlur = (e) => {
    if (e.target.value.length > 0 && !modal) {
      setTypeArray([...typeArray, e.target.value]);
      e.target.value = "";
      setModalInputValue("");
    }
  };

  const handleChangeSelect = (e) => {
    setSelect(e.target.value);
  };

  const getBranchName = (id) => {
    const branch = branchList.find((branch) => branch._id === id);
    return branch.branchName;
  };

  const renderChip = () => {
    return typeArray.map((item, index) => (
      <Chip
        className={classes.chip}
        key={`chip-${index}`}
        label={item}
        onDelete={() => handleDelete(index)}
      />
    ));
  };

  const btnStatus = () => {
    if (!modal && typeArray.length > 0) {
      return false;
    } else if (modal) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const serviceObj = { serviceArray: [] };

    typeArray.forEach((serviceName) => {
      serviceObj.serviceArray.push({ branchId: select, serviceName });
    });

    if (modal) {
      dispatch(
        updateService({
          id: modalId,
          branchId: select,
          branchName: getBranchName(select),
          formData: modalInputValue,
        })
      );
      dispatch(hideModal());
    } else {
      dispatch(createService(encrypt(JSON.stringify(serviceObj))));
    }

    if (!modal) {
      setTypeArray([]);
    }
  };

  const handleChange = (e) => {
    setModalInputValue(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const renderForm = () => {
    return (
      <Card
        variant="outlined"
        color="primary"
        className={`${classes.root} ${modal ? classes.modal : ""}`}
      >
        <CardHeader title="Add Service" />
        <div className={classes.form}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <CardContent>
              <FormControl
                variant="outlined"
                fullWidth
                color="primary"
                className={classes.formControl}
                // error={touched.subType && !!errors.subType}
              >
                <InputLabel id="formSelect">Branch</InputLabel>
                <Select
                  labelId="formSelectLabel"
                  id="formSelect"
                  onChange={handleChangeSelect}
                  value={select}
                  label="Branch"
                >
                  {renderMenuItem()}
                </Select>
              </FormControl>
              <TextField
                id="formTask"
                label="Service"
                onKeyDown={handleKeyDown}
                onBlur={handleTaskBlur}
                variant="outlined"
                onChange={handleChange}
                value={modalInputValue}
                fullWidth
                color="primary"
              />
              {!modal && renderChip()}
            </CardContent>
            <CardActions className={classes.action}>
              <Button
                disabled={btnStatus()}
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </CardActions>
          </form>
        </div>
      </Card>
    );
  };

  if (modal) {
    return renderForm();
  }

  return (
    <Skeleton>
      {renderForm()}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || "error"}>
          <p className="message">{message}</p>
        </Alert>
      </Snackbar>
      {loaderDisplay ? <LinearProgress className="loader" /> : false}
    </Skeleton>
  );
};

export default AddService;
