import crypto from "crypto";

const key = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_ENCRYPTION_KEY : process.env.REACT_APP_DEVELOPMENT_ENCRYPTION_KEY;
export const ImageURL = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_IMAGE_BASE_URL : process.env.REACT_APP_DEVELOPMENT_IMAGE_BASE_URL;
export const FileBaseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;


export const encrypt = function (text) {
  const iv = crypto.randomBytes(16);
  let cipher = crypto.createCipheriv("aes-256-cbc", key, iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return { iv: iv.toString("hex"), encryptedData: encrypted.toString("hex") };
};

export const decrypt = function (text) {
  var iv = Buffer.from(text.iv, "hex");
  let encryptedText = Buffer.from(text.encryptedData, "hex");
  let decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return extractJson(decrypted.toString());
};
export const extractJson = function (data) {
  var s = data
    .replace(/\\n/g, "\\n")
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, "\\&")
    .replace(/\\r/g, "\\r")
    .replace(/\\t/g, "\\t")
    .replace(/\\b/g, "\\b")
    .replace(/\\f/g, "\\f");
  s = s.replace(/[\u0000-\u0019]+/g, "");
  var o = JSON.parse(s);
  return o;
};

export const isInBetween = (value1, value2, value) => {
  if (
    Object.prototype.toString.call(value1) === "[object Date]" &&
    Object.prototype.toString.call(value2) === "[object Date]"
  ) {
    if (!isNaN(value1.getTime()) && !isNaN(value2.getTime())) {
      const newValue = new Date(value.split("T")[0]).toISOString();
      return (
        newValue >= value1.toISOString() && newValue <= value2.toISOString()
      );
    }

    return false;
  }
  return false;
};

const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getFormatedDate = (isoDate) => {
  const fDate = new Date(isoDate);
  const year = fDate.getFullYear();
  const month = fDate.getMonth();
  let date = fDate.getDate();

  if (date < 10) {
    date = `0${date}`;
  }

  return `${year}-${monthArray[month]}-${date}`;
};
