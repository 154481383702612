import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "1rem 0",
  },
  chip: {
    "&:not(:last-child)": {
      margin: "0 4px 1rem 0",
    },
  },
}));

const DropDownChip = ({
  modal,
  keyName,
  Name,
  id,
  labelName,
  dataList,
  data,
  setData,
}) => {
  const classes = useStyles();

  const onItemClick = (id, name) => {
    setData({ ...data, [id]: name });
  };

  const renderOption = () =>
    dataList.map((item) => {
      return (
        <MenuItem
          onClick={() => onItemClick(item._id, item[keyName])}
          key={item._id}
          value={item._id}
        >
          {item[keyName]}
        </MenuItem>
      );
    });

  const handleDelete = (id) => {
    const dataCopy = { ...data };
    delete dataCopy[id];
    setData(dataCopy);
  };

  const renderChip = () => {
    const jsxArr = [];
    for (const key in data) {
      const elem = (
        <Chip
          className={classes.chip}
          key={`chip-${key}`}
          label={data[key]}
          onDelete={() => handleDelete(key)}
        />
      );
      jsxArr.push(elem);
    }
    return jsxArr;
  };

  if (!modal) {
    return (
      <>
        <FormControl
          variant="outlined"
          fullWidth
          color="primary"
          className={classes.formControl}
        >
          <InputLabel id="formSelect1">Service</InputLabel>
          <Select name={Name} labelId={id} id={id} label={labelName} value="">
            {renderOption()}
          </Select>
        </FormControl>

        {renderChip()}
      </>
    );
  }
  return null;
};

export default DropDownChip;
