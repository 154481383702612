import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { IoIosClose } from "react-icons/io";
import { makeStyles } from "@material-ui/core/styles";

import { hidePopup } from "components/ConfirmPopup/popupSlice";
import { deleteCategory } from "features/Category/categorySlice";
import { deleteSubcategory } from "features/Subcategory/subCategorySlice";
import { deleteService } from "features/Service/serviceSlice";
import { deleteBranch } from "features/Branch/branchSlice";
import { deleteHome } from "features/home/homeSlice";
import { deleteTodos } from "features/Todo/todoSlice";
import { logOut } from "features/Login/loginSlice";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: "rgba(0, 0, 0, .8)",
  },

  modal: {
    position: "absolute",
    left: "50%",
    top: "35%",
    transform: "translate(-50%, -50%)",
    zIndex: 99,
    minWidth: "320px",
  },

  close: {
    display: "block",
    marginLeft: "auto",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#d3d3d3",

    "& > svg": {
      fontSize: "3rem",
    },
  },

  form: {
    maxHeight: "88vh",
    overflow: "auto",
    backgroundColor: "#fff",
    padding: "1rem 1rem 0 1rem",
  },

  btnContainer: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    textAlign: "center",
    padding: "1rem",
  },
  text: {
    textAlign: "center",
  },
}));

const Modal = ({ parent }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const id = useSelector((state) => state.popup.id);

  function onDismiss() {
    dispatch(hidePopup());
  }

  function handleYes() {
    switch (parent) {
      case "category":
        dispatch(deleteCategory({ id }));
        break;
      case "subcategory":
        dispatch(deleteSubcategory({ id }));
        break;
      case "service":
        dispatch(deleteService({ id }));
        break;
      case "branch":
        dispatch(deleteBranch({ id }));
        break;
      case "home":
        dispatch(deleteHome({ id }));
        break;
      case "todos":
        dispatch(deleteTodos({ id }));
        break;
      case "topbar":
        dispatch(logOut());
        break;
      default:
        break;
    }
    onDismiss();
  }

  return ReactDOM.createPortal(
    <div onClick={onDismiss} className={styles.modalContainer}>
      <div onClick={(e) => e.stopPropagation()} className={styles.modal}>
        <button onClick={onDismiss} className={styles.close}>
          <IoIosClose />
        </button>

        <div className={styles.form}>
          <p className={styles.text}>
            {parent === "topbar"
              ? "Are you sure you want to logout"
              : "Are you sure you want to delete the Item?"}
          </p>
          <div className={styles.btnContainer}>
            <button onClick={onDismiss} className={styles.btn}>
              No
            </button>
            |
            <button onClick={handleYes} className={styles.btn}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector("#popup")
  );
};

export default Modal;
