import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import Skeleton from "components/Skeleton";
import {
  createBranch,
  updateBranch,
  clearMessage,
} from "features/Branch/branchSlice";
import { encrypt } from "utils";
import { hideModal } from "Modal/modalSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(2, 0),
    },
  },
  modal: {
    maxHeight: "80vh",
    overflow: "auto",
  },
  chip: {
    marginBottom: "qrem",

    "&:not(:last-child)": {
      marginRight: "4px",
    },
  },
  formControl: {
    margin: "1rem 0",
  },
  action: {
    padding: theme.spacing(2),

    "& > button": {
      padding: theme.spacing(1, 5),
    },
  },
}));

const AddBranch = ({ modal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [typeArray, setTypeArray] = useState([]);
  const [open, setOpen] = React.useState(false);

  const loaderDisplay = useSelector((state) => state.branch.loading);
  const message = useSelector((state) => state.branch.message);
  const severity = useSelector((state) => state.branch.severity);
  const modalId = useSelector((state) => state.modal.id);
  const [modalInputValue, setModalInputValue] = useState("");


  const formModalData = useSelector((state) => state.branch.list[modalId]);

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);

      setTimeout(() => {
        dispatch(clearMessage());
      }, 4100);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    setOpen(false);

    if (modal) {
      setModalInputValue(formModalData && formModalData.branchName);
    }
    // eslint-disable-next-line
  }, []);

  const handleDelete = (index) => {
    const updatedType = typeArray;
    updatedType.splice(index, 1);
    setTypeArray([...updatedType]);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.target.value.length > 0 && !modal) {
      e.preventDefault();
      setTypeArray([...typeArray, e.target.value]);
      setModalInputValue("");
    }
  };

  const renderChip = () => {
    return typeArray.map((item, index) => (
      <Chip
        className={classes.chip}
        key={`chip-${index}`}
        label={item}
        onDelete={() => handleDelete(index)}
      />
    ));
  };

  const handleTaskBlur = (e) => {
    if (e.target.value.length > 0 && !modal) {
      setTypeArray([...typeArray, e.target.value]);
      e.target.value = "";
      setModalInputValue("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const branchObj = { branchArray: [] };

    typeArray.forEach((branchName) => {
      branchObj.branchArray.push({ branchName });
    });

    if (modal) {
      dispatch(updateBranch({ id: modalId, formData: modalInputValue }));
      dispatch(hideModal());
    } else {
      dispatch(createBranch(encrypt(JSON.stringify(branchObj))));
      setTypeArray([]);
    }
  };

  const handleChange = (e) => {
    setModalInputValue(e.target.value);
  };

  const btnStatus = () => {
    if (!modal && typeArray.length > 0) {
      return false;
    } else if (modal) {
      return false;
    } else {
      return true;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const renderForm = () => {
    return (
      <Card
        variant="outlined"
        color="primary"
        className={`${classes.root} ${modal ? classes.modal : ""}`}
      >
        <CardHeader title="Add Branch" />
        <div className={classes.form}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <CardContent>
              <TextField
                id="formTask"
                label="Branch"
                onKeyDown={handleKeyDown}
                onBlur={handleTaskBlur}
                variant="outlined"
                onChange={handleChange}
                value={modalInputValue}
                fullWidth
                color="primary"
              />
              {!modal && renderChip()}
            </CardContent>
            <CardActions className={classes.action}>
              <Button
                disabled={btnStatus()}
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </CardActions>
          </form>
        </div>
      </Card>
    );
  };

  if (modal) {
    return renderForm();
  }

  return (
    <Skeleton>
      {renderForm()}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || "error"}>
          <p className="message">{message}</p>
        </Alert>
      </Snackbar>
      {loaderDisplay ? <LinearProgress className="loader" /> : false}
    </Skeleton>
  );
};

export default AddBranch;
