import React from "react";
import { useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import { IoIosClose } from "react-icons/io";
import { makeStyles } from "@material-ui/core/styles";

import { hideModal } from "Modal/modalSlice";
import AddCategory from "features/Category/AddCategory";
import AddSubcategory from "features/Subcategory/AddSubCategory";
import AddBranch from "features/Branch/AddBranch";
import AddService from "features/Service/AddService";
import CreateTodo from "features/Todo/CreateTodo";
import Home from "features/home/AddHome";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: "rgba(0, 0, 0, .8)",
  },

  modal: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99,
    width: "40%",
    minWidth: "320px",
  },

  close: {
    display: "block",
    marginLeft: "auto",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#fff",

    "& > svg": {
      fontSize: "3rem",
    },
  },

  form: {
    maxHeight: "88vh",
    overflow: "auto",
  },
}));

const Modal = ({ title, type }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  function onDismiss() {
    dispatch(hideModal());
  }

  const renderForm = () => {
    switch (type) {
      case "category":
        return <AddCategory title={title} modal />;
      case "subcategory":
        return <AddSubcategory title={title} modal />;
      case "branch":
        return <AddBranch title={title} modal />;
      case "service":
        return <AddService title={title} modal />;
      case "todo":
        return <CreateTodo title={title} modal />;
      case "home":
        return <Home title={title} modal />;
      default:
        return false;
    }
  };

  return ReactDOM.createPortal(
    <div onClick={onDismiss} className={styles.modalContainer}>
      <div onClick={(e) => e.stopPropagation()} className={styles.modal}>
        <button onClick={onDismiss} className={styles.close}>
          <IoIosClose />
        </button>

        <div className={styles.form}>{renderForm()}</div>
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

export default Modal;
