import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import { string, object } from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import { loginRequest } from "features/Login/loginSlice";
import { encrypt } from "utils";
import { ReactComponent as Logo } from "images/logo.svg";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: "30rem",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "left",
    fontSize: "2rem",
    border: "2px solid var(--primary)",
    padding: "6rem 3.5rem",
    borderRadius: "1rem",
    fontWeight: "600",
    backgroundColor: "#fff",

    "@media (max-width: 37.5em)": {
      width: "92vw",
    },

    "& .MuiTextField-root": {
      margin: "1rem 0",
    },

    "& .MuiButtonBase-root": {
      width: "10rem",
      padding: ".7rem 0",
      marginTop: ".8rem",
    },
  },

  container: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#343a40",
  },

  logo: {
    marginBottom: "1rem",
    textAlign: "center",
  },
}));

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const loaderDisplay = useSelector((state) => state.auth.loading);
  const message = useSelector((state) => state.auth.message);

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);
    }
  }, [message]);

  const validationSchema = object().shape({
    email: string().email("Invalid email address").required("Required"),
    password: string()
      .min(8, "Must be 8 characters or more")
      .required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const encryptData = encrypt(JSON.stringify(values));
    dispatch(loginRequest([encryptData, () => history.push("/")]));

    resetForm();
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                  error={touched.email && !!errors.email}
                  name="email"
                  id="formEmail"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  helperText={touched.email ? errors.email : false}
                  variant="outlined"
                  fullWidth
                  color="primary"
                />

                <TextField
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  variant="outlined"
                  name="password"
                  id="formPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  helperText={touched.password ? errors.password : false}
                  error={touched.password && !!errors.password}
                  fullWidth
                />

                <Button type="submit" variant="contained" color="primary">
                  Login
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={"error"}>
          <p className="message">{message}</p>
        </Alert>
      </Snackbar>
      {loaderDisplay ? <LinearProgress className="loader" /> : false}
    </>
  );
};

export default Login;
