import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { AiFillDelete } from "react-icons/ai";

import Skeleton from "components/Skeleton";
import useProviderList from "customHooks/providerList/useProviderList";
import { toggleAdminVerify } from "customHooks/providerList/providerListSlice";
import { deactivateProvider } from "customHooks/providerList/providerListSlice";
import { emptyProviderList } from "customHooks/providerList/providerListSlice";
import usePagination from "customHooks/usePagination";

const useStyles = makeStyles({
  table: {
    minWidth: 1350,
  },
  container: {
    margin: "1rem",
  },
  filter: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    padding: "1rem",
  },
});

const AllProvider = ({ home }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [filter, setFilter] = useState("all");
  const [callStart, setCallStart] = useState(0);
  let verifyStatus;

  if (filter === "verified") {
    verifyStatus = true;
  } else if (filter === "all") {
    verifyStatus = undefined;
  } else {
    verifyStatus = false;
  }

  const handleCallStart = (value) => {
    setCallStart(value);
  };

  useEffect(() => {
    setCallStart(0);
    dispatch(emptyProviderList());
  }, [verifyStatus, dispatch]);

  const providerList =  useProviderList(callStart, 15, verifyStatus);
  console.log(providerList, "providerList");

  usePagination(providerList, callStart, handleCallStart);

  const handleChangeSwitch = (id) => {
    dispatch(toggleAdminVerify(id));
  };

  const getNameStringFromArray = (key, data) => {
    let nameArray = [];
    data.forEach((element) => {
      nameArray.push(element[key]);
    });

    return nameArray.join();
  };

  const deactivateFn = (status, id) => {
    const deactivateData = {
      providerId: id,
      status: status,
    };
    dispatch(deactivateProvider(deactivateData));
    // window.location.reload();
  };

  const renderRow = () => {
    return providerList.map((item, index) => {
      return (
        <TableRow
          key={item._id}
          style={{ background: item.status === 2 && "#80808080" }}
        >
          <TableCell component="th" scope="row">
            <Switch
              checked={item.adminVerified ? true : false}
              onChange={() => handleChangeSwitch(item._id)}
              name="verified"
              color="primary"
            />
          </TableCell>
          <TableCell component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.companyName}
          </TableCell>
          <TableCell>{item.email}</TableCell>
          <TableCell align="right">{item.contactPerson}</TableCell>
          <TableCell colSpan={3} align="right">
            {item.address
              ? `${item.address.street}, ${item.address.city}, ${item.address.postalCode}`
              : null}
          </TableCell>
          <TableCell align="right">{item.phoneNumber}</TableCell>
          <TableCell align="right">
            {getNameStringFromArray("serviceName", item.serviceId)}
          </TableCell>
          <TableCell align="right">
            {getNameStringFromArray("branchName", item.branchId)}
          </TableCell>
          <TableCell align="right">{item.isVerified ? "Yes" : "No"}</TableCell>
          <TableCell align="right">
            <AiFillDelete
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => deactivateFn(item.status, item._id)}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <>
        <div className={classes.filter}>
          <Select
            name="subType"
            labelId="formSelectLabel"
            id="formSelect"
            onChange={handleChange}
            value={filter}
            label="Type"
          >
            <MenuItem value={"all"}>ALL</MenuItem>
            <MenuItem value={"verified"}>Admin Verified</MenuItem>
            <MenuItem value={"notVerified"}>Not Admin Verified</MenuItem>
          </Select>
        </div>

        <TableContainer className={classes.container} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Admin Approved</TableCell>
                <TableCell>#</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right">Contact Person</TableCell>
                <TableCell align="right" colSpan={3}>
                  Address
                </TableCell>
                <TableCell align="right">Phone Number</TableCell>
                <TableCell align="right">Service</TableCell>
                <TableCell align="right">Branch</TableCell>
                <TableCell align="right">Verified</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderRow()}</TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const handleChange = (e) => {
    setFilter(e.target.value);
  };

  if (home) {
    return renderTable();
  }

  return <Skeleton>{renderTable()}</Skeleton>;
};

export default AllProvider;
