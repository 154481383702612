import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import { string, object } from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { ImageURL } from "utils";

import Skeleton from "components/Skeleton";
import { createHome, clearMessage, updateHome } from "features/home/homeSlice";
import { encrypt } from "utils";
import ChipInput from "components/ChipInput/ChipInput";
import DropDownChip from "components/DropDownChip/DropDownChip";
import useServiceList from "customHooks/useServiceList";
import { hideModal } from "Modal/modalSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2, 0),
    },
  },
  chip: {
    "&:not(:last-child)": {
      margin: "0 4px 1rem 0",
    },
  },
  formControl: {
    margin: "1rem 0",
  },
  action: {
    padding: theme.spacing(2),

    "& > button": {
      padding: theme.spacing(1, 5),
    },
  },
  img: {
    width: "100%",
  },
}));

const AddHome = ({ modal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [open, setOpen] = React.useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileError1, setFileError1] = useState(false);
  const [taskArray, setTaskArray] = useState([]);
  const [serviceObj, setServiceObj] = useState({});

  const loaderDisplay = useSelector((state) => state.home.loading);
  const message = useSelector((state) => state.home.message);
  const severity = useSelector((state) => state.home.severity);
  const serviceList = useServiceList(-1, 0);

  const modalId = useSelector((state) => state.modal.id);
  const formModalData = useSelector((state) => state.home.list[modalId]);

  useEffect(() => {
    const taskArray = [];
    if (
      modal &&
      formModalData.task &&
      taskArray.length !== formModalData.task.length
    ) {
      for (const item of formModalData.task) {
        taskArray.push(item.taskName);
      }

      setTaskArray(taskArray);
    }
  }, [formModalData, modal]);

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);

      setTimeout(() => {
        dispatch(clearMessage());
      }, 4100);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    setOpen(false);
  }, []);

  const initialValues = {
    description:
      modal && formModalData.description ? formModalData.description : "",
    imageName: modal && formModalData.imageName ? formModalData.imageName : "",
  };

  const validationSchema = object().shape({
    description: string().required("Required"),
    imageName: string().required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    let encryptedData;
    if (modal) {
      if (modal && !file) {
        encryptedData = encrypt(
          JSON.stringify({
            ...values,
            task: taskArray,
            image: formModalData.image,
          })
        );
      } else if (modal && !file1) {
        encryptedData = encrypt(
          JSON.stringify({
            ...values,
            task: taskArray,
            homeImage: formModalData.homeImage,
          })
        );
      } else if (modal && !file && !file1) {
        encryptedData = encrypt(
          JSON.stringify({
            ...values,
            task: taskArray,
            image: formModalData.image,
            homeImage: formModalData.homeImage,
          })
        );
      } else {
        encryptedData = encrypt(
          JSON.stringify({
            ...values,
            task: taskArray,
          })
        );
      }
    } else {
      encryptedData = encrypt(
        JSON.stringify({
          ...values,
          task: taskArray,
          serviceId: Object.keys(serviceObj),
        })
      );
    }

    formData.append("data", JSON.stringify(encryptedData));

    if (file) {
      formData.append("image", file);
    }

    if (file1) {
      formData.append("homeImage", file1);
    }

    if (modal) {
      dispatch(updateHome([modalId, formData]));
      dispatch(hideModal());
    } else {
      dispatch(createHome(formData));
      resetForm();
      setTaskArray([]);
      setServiceObj({});
      document.querySelector("#formFile").value = "";
      document.querySelector("#formFile1").value = "";
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const btnStatus = (isValid, dirty) => {
    // checking if upload or edit

    if (!modal) {
      return !(
        isValid &&
        dirty &&
        !fileError &&
        !fileError1 &&
        file &&
        taskArray.length > 0 &&
        Object.keys(serviceObj).length > 0
      );
    }
    return false;
  };

  const renderImage = (type) => {
    if (modal && formModalData.image) {
      const imageKey = type === "file" ? "image" : "homeImage";
      return (
        <img
          className={classes.img}
          src={`${ImageURL}${formModalData[imageKey]}`}
          alt={formModalData.imageName}
        />
      );
    }
  };

  const handleFileChange = (event, type) => {
    const fileArray =
      event.currentTarget.files[0] &&
      event.currentTarget.files[0].name.split(".");

    const fileExt = fileArray && fileArray[fileArray && fileArray.length - 1];
    if (fileExt === "png" || fileExt === "jpg") {
      type === "file"
        ? setFile(event.currentTarget.files[0])
        : setFile1(event.currentTarget.files[0]);
      type === "file" ? setFileError(false) : setFileError1(false);
    } else {
      type === "file" ? setFileError(true) : setFileError1(true);
    }
  };

  const renderForm = () => {
    return (
      <Card variant="outlined" color="primary" className={classes.root}>
        <CardHeader title="Add Home" />
        <div className={classes.form}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <CardContent>
                  <TextField
                    error={touched.imageName && !!errors.imageName}
                    name="imageName"
                    id="imageName"
                    label="Image Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.imageName}
                    helperText={touched.imageName ? errors.imageName : false}
                    variant="outlined"
                    fullWidth
                    color="primary"
                  />

                  <TextField
                    error={touched.description && !!errors.description}
                    name="description"
                    id="formDescription"
                    label="Description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    helperText={
                      touched.description ? errors.description : false
                    }
                    variant="outlined"
                    fullWidth
                    color="primary"
                  />

                  <ChipInput
                    modal={modal}
                    data={taskArray}
                    setData={setTaskArray}
                  />
                  <DropDownChip
                    modal={modal}
                    keyName="serviceName"
                    Name="service"
                    id="serviceDropDown"
                    labelName="Service"
                    dataList={serviceList}
                    data={serviceObj}
                    setData={setServiceObj}
                  />

                  <TextField
                    id="formFile"
                    type="file"
                    error={fileError}
                    variant="outlined"
                    helperText={`Allowed format jpg and png ${
                      fileError ? "(invalid file format)" : ""
                    }`}
                    onChange={(e) => handleFileChange(e, "file")}
                    fullWidth
                    color="primary"
                  />
                  {renderImage("file")}

                  <TextField
                    id="formFile1"
                    type="file"
                    error={fileError1}
                    variant="outlined"
                    helperText={`Allowed format jpg and png ${
                      fileError1 ? "(invalid file format)" : ""
                    }`}
                    onChange={(e) => handleFileChange(e, "file1")}
                    fullWidth
                    color="primary"
                  />
                  {renderImage("file1")}
                </CardContent>
                <CardActions className={classes.action}>
                  <Button
                    disabled={btnStatus(isValid, dirty)}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </CardActions>
              </form>
            )}
          </Formik>
        </div>
      </Card>
    );
  };
  if (modal) {
    return renderForm();
  }
  return (
    <Skeleton>
      {renderForm()}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || "error"}>
          <p className="message">{message}</p>
        </Alert>
      </Snackbar>
      {loaderDisplay ? <LinearProgress className="loader" /> : false}
    </Skeleton>
  );
};

export default AddHome;
