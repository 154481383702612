import { useEffect } from "react";

const usePagination = (list, callStart, handleCallStart) => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const totalHeight = document.body.offsetHeight;
      const scrollPos = window.scrollY;

      // Various condition to pagination 3 one is stoping
      //it for further api call if data returned is empty
      if (
        scrollPos + 10 > totalHeight - windowHeight &&
        windowHeight > 400 &&
        list.length >= callStart
      ) {
        handleCallStart(callStart + 15);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [callStart, list]);

  return callStart;
};

export default usePagination;
