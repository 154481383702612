import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTodos } from "features/Todo/todoSlice";

const useTodosList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTodos());
  }, [dispatch]);

  return useSelector((state) => Object.values(state.todos.list));
};

export default useTodosList;
