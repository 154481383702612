export const ROOT = "/";
export const LOGIN = "/login";
export const ADD_CATEGORY = "/addCategory";
export const VIEW_ALL_CATEGORIES = "/viewAllCategories";
export const ADD_SUBCATEGORY = "/addsubcategory";
export const VIEW_ALL_SUB_CATEGORIES = "/viewAllSubcategories";
export const ALL_PROVIDERS = "/allProviders";
export const ADD_BRANCH = "/addBranch";
export const VIEW_ALL_BRANCH = "/viewAllBranch";
export const ADD_SERVICE = "/addService";
export const VIEW_ALL_SERVICE = "/viewAllService";
export const ADD_HOME = "/addHome";
export const VIEW_ALL_HOME = "/viewAllHome";
export const ADD_TODOS = "/addtodo";
export const VIEW_ALL_TODOS = "/viewAlltodos";
