import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Login from "features/Login";
import Dashboard from "features/Dashboard";
import AddCategory from "features/Category/AddCategory";
import AddSubCategory from "features/Subcategory/AddSubCategory";
import ViewAllCategories from "features/Category/ViewAllCategories";
import ViewAllSubcategories from "features/Subcategory/ViewAllSubcategory";
import AllProviders from "features/Provider/AllProvider";
import AddBranch from "features/Branch/AddBranch";
import ViewAllBranch from "features/Branch/ViewAllBranch";
import AddService from "features/Service/AddService";
import ViewAllService from "features/Service/ViewAllService";
import AddHome from "features/home/AddHome";
import ViewALLHome from "features/home/ViewAllHome";
import AddTodos from "features/Todo/CreateTodo";
import ViewAllTodos from "features/Todo/ViewAllTodos";
import * as routes from "routes";
import CustomRoute from "routes/customRoute";

const App = () => {
  const authenticated = localStorage.getItem("token");

  return (
    <Router>
      <Switch>
        <Route
          render={(props) =>
            authenticated ? <Redirect to="/" /> : <Login {...props} />
          }
          path={routes.LOGIN}
          exact
        />
        <CustomRoute
          restricted
          path={routes.ROOT}
          exact
          component={Dashboard}
        />
        <CustomRoute
          restricted
          path={routes.ADD_CATEGORY}
          exact
          component={AddCategory}
        />
        <CustomRoute
          restricted
          path={routes.VIEW_ALL_CATEGORIES}
          exact
          component={ViewAllCategories}
        />
        <CustomRoute
          restricted
          path={routes.ADD_SUBCATEGORY}
          exact
          component={AddSubCategory}
        />
        <CustomRoute
          restricted
          path={routes.VIEW_ALL_SUB_CATEGORIES}
          exact
          component={ViewAllSubcategories}
        />
        <CustomRoute
          restricted
          path={routes.ALL_PROVIDERS}
          exact
          component={AllProviders}
        />
        <CustomRoute
          restricted
          path={routes.ADD_BRANCH}
          exact
          component={AddBranch}
        />
        <CustomRoute
          restricted
          path={routes.VIEW_ALL_BRANCH}
          exact
          component={ViewAllBranch}
        />
        <CustomRoute
          restricted
          path={routes.ADD_SERVICE}
          exact
          component={AddService}
        />
        <CustomRoute
          restricted
          path={routes.VIEW_ALL_SERVICE}
          exact
          component={ViewAllService}
        />
        <CustomRoute
          restricted
          path={routes.ADD_HOME}
          exact
          component={AddHome}
        />
        <CustomRoute
          restricted
          path={routes.VIEW_ALL_HOME}
          exact
          component={ViewALLHome}
        />
        <CustomRoute
          restricted
          path={routes.ADD_TODOS}
          exact
          component={AddTodos}
        />
        <CustomRoute
          restricted
          path={routes.VIEW_ALL_TODOS}
          exact
          component={ViewAllTodos}
        />
      </Switch>
    </Router>
  );
};

export default App;
