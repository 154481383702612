import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllBranch } from "features/Branch/branchSlice";

const useBranchList = (start, limit) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBranch([start, limit]));
    // eslint-disable-next-line
  }, [start, limit]);

  return useSelector((state) => Object.values(state.branch.list));
};

export default useBranchList;
