import { configureStore } from "@reduxjs/toolkit";

import loginReducer from "features/Login/loginSlice";
import providerListReducer from "customHooks/providerList/providerListSlice";
import categoryReducer from "features/Category/categorySlice";
import subcategoryReducer from "features/Subcategory/subCategorySlice";
import modalReducer from "Modal/modalSlice";
import getInfoReducer from "components/Info/getInfoSlice";
import branchReducer from "features/Branch/branchSlice";
import serviceReducer from "features/Service/serviceSlice";
import sideBarReducer from "components/SideBar/sideBarSlice";
import popupReducer from "components/ConfirmPopup/popupSlice";
import homeReducer from "features/home/homeSlice";
import todosReducer from "features/Todo/todoSlice";

export default configureStore({
  reducer: {
    auth: loginReducer,
    providerList: providerListReducer,
    categories: categoryReducer,
    subCategories: subcategoryReducer,
    modal: modalReducer,
    info: getInfoReducer,
    branch: branchReducer,
    service: serviceReducer,
    sideBar: sideBarReducer,
    popup: popupReducer,
    home: homeReducer,
    todos: todosReducer,
  },
});
