import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MdDelete, MdEdit } from "react-icons/md";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Modal from "Modal";

import Skeleton from "components/Skeleton";
import { getAllHome, clearMessage, getHome } from "features/home/homeSlice";
import usePagination from "customHooks/usePagination";
import Popup from "components/ConfirmPopup";
import { showPopup } from "components/ConfirmPopup/popupSlice";
import { showModal } from "Modal/modalSlice";
import { encrypt } from "utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  table: {
    minWidth: 850,
  },
  container: {
    margin: "1rem",
  },
  button: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",

    "& > svg": {
      fontSize: "1.2rem",
    },
  },
});

const ViewAllHome = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [callStart, setCallStart] = useState(0);
  const [open, setOpen] = React.useState(false);

  const loaderDisplay = useSelector((state) => state.home.loading);
  const message = useSelector((state) => state.home.message);
  const severity = useSelector((state) => state.home.severity);

  const popupDisplay = useSelector((state) => state.popup.visibility);
  const modalStatus = useSelector((state) => state.modal.visibility);

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);

      setTimeout(() => {
        dispatch(clearMessage());
      }, 4100);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    dispatch(getAllHome([callStart, 15]));
    // eslint-disable-next-line
  }, [callStart]);

  const handleDelete = (id) => {
    dispatch(showPopup({ visibility: true, id }));
  };

  const handleCallStart = (value) => {
    setCallStart(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleEdit = (id) => {
    dispatch(showModal({ id }));
    dispatch(getHome(encrypt(JSON.stringify({ id }))));
  };

  const getArrayFromObject = (data, type) => {
    const array = [];
    for (const key in data) {
      array.push(data[key][type]);
    }

    return array.join(", ");
  };

  const homeList = useSelector((state) => Object.values(state.home.list));

  usePagination(homeList, callStart, handleCallStart);

  const renderRow = () => {
    return homeList.map((item, index) => {
      return (
        <TableRow key={item._id}>
          <TableCell component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.imageName}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.description}
          </TableCell>
          <TableCell component="th" scope="row">
            {getArrayFromObject(item.serviceId, "serviceName")}
          </TableCell>
          <TableCell align="right">
            <button
              onClick={() => handleEdit(item._id)}
              className={classes.button}
              style={{ marginRight: "10px" }}
            >
              <MdEdit />
            </button>
          </TableCell>
          <TableCell align="right">
            <button
              onClick={() => handleDelete(item._id)}
              className={classes.button}
            >
              <MdDelete />
            </button>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Skeleton>
      <TableContainer className={classes.container} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell component="th" scope="row">
                Image Name
              </TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Service</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderRow()}</TableBody>
        </Table>
      </TableContainer>
      {modalStatus ? <Modal title="Edit Home" type="home" /> : ""}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || "error"}>
          <p className="message">{message}</p>
        </Alert>
      </Snackbar>
      {loaderDisplay ? <LinearProgress className="loader" /> : false}
      {popupDisplay ? <Popup parent="home" /> : false}
    </Skeleton>
  );
};

export default ViewAllHome;
