import React from "react";
import { Redirect, Route } from "react-router-dom";

const CustomRoute = ({ component: Component, restricted, path, ...rest }) => {
  const authenticated = localStorage.getItem("token");

  return (
    <Route
      // restricted = false meaning public route
      // restricted = true meaning restricted route
      {...rest}
      render={(props) =>
        authenticated && restricted ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default CustomRoute;
