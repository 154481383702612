import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { getInfo } from "./getInfoSlice";
import { IoIosGitBranch } from "react-icons/io";
import { FiGrid } from "react-icons/fi";
import { TiTickOutline } from "react-icons/ti";
import { FaUserTie } from "react-icons/fa";

const useStyles = makeStyles({
  smallBox: {
    display: "block",
    marginBottom: "20px",
    position: "relative",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "none",

      "& svg": {
        fontSize: "5rem",
      },
    },

    "& h3": {
      fontSize: "2.5rem",
      fontWeight: "700",
      margin: "0 0 1rem 0",
      padding: 0,
      whiteSpace: "nowrap",
      color: "#fff",
    },

    "& p": {
      fontSize: "1rem",
      color: "#fff",
      fontWeight: 400,
    },
  },
  innerBox: {
    borderRadius: "0.14rem",
    boxShadow: "0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2)",
    transition: "all 0.2s linear",
    height: "11rem",

    "@media only screen and (max-width: 86em)": {
    height: "9rem",
    },

    "&:hover": {
      filter: "brightness(0.8)",
    },
  },
  icon: {
    color: "rgba(0, 0, 0, 0.15)",
    zIndex: 0,

    "& svg": {
      fontSize: "4rem",
      position: "absolute",
      right: "1.5rem",
      top: "1.5rem",
      transition: "all 0.2s linear",
    },
  },

  inner: {
    padding: "1.2rem",
  },

  container: {
    padding: "1rem 1rem 1rem 17rem",
    margin: "4rem 0 0 0",
    width: "100%",
  },
});

const Info = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const info = useSelector((state) => state.info);

  useEffect(() => {
    dispatch(getInfo());
    // eslint-disable-next-line
  }, [...Object.values(info)]);

  return (
    <>
      <Grid xs={6} sm={3} item>
        <Link to="/allProviders" className={classes.smallBox}>
          <Box className={classes.innerBox} bgcolor="warning.main">
            <div className={classes.inner}>
              <h3>{info.totalProvider}</h3>
              <p>Total Provider</p>
            </div>
            <div className={classes.icon}>
              <FaUserTie />
            </div>
          </Box>
        </Link>
      </Grid>

      <Grid xs={6} sm={3} item>
        <Link to="/allProviders" className={classes.smallBox}>
          <Box className={classes.innerBox} bgcolor="secondary.main">
            <div className={classes.inner}>
              <h3>{info.totalVerifiedProvider}</h3>
              <p>Verified provider</p>
            </div>
            <div className={classes.icon}>
              <TiTickOutline />
            </div>
          </Box>
        </Link>
      </Grid>
      <Grid xs={6} sm={3} item>
        <Link to="/viewAllCategories" className={classes.smallBox}>
          <Box className={classes.innerBox} bgcolor="primary.main">
            <div className={classes.inner}>
              <h3>{info.totalCategory}</h3>
              <p>Total Categories</p>
            </div>
            <div className={classes.icon}>
              <FiGrid />
            </div>
          </Box>
        </Link>
      </Grid>
      <Grid xs={6} sm={3} item>
        <Link to="/viewAllBranch" className={classes.smallBox}>
          <Box className={classes.innerBox} bgcolor="success.main">
            <div className={classes.inner}>
              <h3>{info.totalBranch}</h3>
              <p>Total Branch</p>
            </div>
            <div className={classes.icon}>
              <IoIosGitBranch />
            </div>
          </Box>
        </Link>
      </Grid>
    </>
  );
};

export default Info;
