import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import { string, object } from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Chip from "@material-ui/core/Chip";
import useServiceList from "customHooks/useServiceList";
import { FileBaseUrl } from "utils";

import Skeleton from "components/Skeleton";
import useGetAllCategory from "customHooks/category/useGetAllCategory";
import {
  createSubcategory,
  clearMessage,
  updateSubcategory,
} from "features/Subcategory/subCategorySlice";
import ChipInput from "components/ChipInput/ChipInput";
import { hideModal } from "Modal/modalSlice";
import { encrypt } from "utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2, 0),
    },
  },
  chip: {
    "&:not(:last-child)": {
      margin: "0 4px 1rem 0",
    },
  },
  formControl: {
    margin: "1rem 0",
  },
  action: {
    padding: theme.spacing(2),

    "& > button": {
      padding: theme.spacing(1, 5),
    },
  },
  img: {
    width: "100%",
  },
}));

const AddSubCategory = ({ modal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [file, setFile] = useState();
  const [open, setOpen] = React.useState(false);
  const [fileError, setFileError] = useState(false);
  const [taskArray, setTaskArray] = useState([]);
  const [serviceObj, setServiceObj] = useState({});

  const modalId = useSelector((state) => state.modal.id);
  const formModalData = useSelector(
    (state) => state.subCategories.list[modalId]
  );

  const loaderDisplay = useSelector((state) => state.subCategories.loading);
  const message = useSelector((state) => state.subCategories.message);
  const severity = useSelector((state) => state.subCategories.severity);

  const serviceList = useServiceList(-1, 0);

  useEffect(() => {
    const taskArray = [];
    if (
      modal &&
      formModalData.task &&
      taskArray.length !== formModalData.task.length
    ) {
      for (const item of formModalData.task) {
        taskArray.push(item.taskName);
      }

      setTaskArray(taskArray);
    }
  }, [formModalData, modal]);

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);

      setTimeout(() => {
        dispatch(clearMessage());
      }, 4400);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    setOpen(false);
  }, []);

  const initialValues = {
    subCategoryName:
      modal && formModalData.subCategoryName
        ? formModalData.subCategoryName
        : "",
    description:
      modal && formModalData.description ? formModalData.description : "",
    categoryId:
      modal && formModalData.categoryId && formModalData.categoryId._id
        ? formModalData.categoryId._id
        : "",
  };

  const catogriesList = useGetAllCategory(-1, 0);

  const validationSchema = object().shape({
    subCategoryName: string().required("Required"),
    description: string().required("Required"),
    categoryId: string().required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    let modalValues = { ...values };
    if (modal) {
      delete modalValues.subType;
    }

    let encryptedData;
    if (modal) {
      if (modal && !file) {
        encryptedData = encrypt(
          JSON.stringify({
            ...modalValues,
            task: taskArray,
            image: formModalData.image,
          })
        );
      } else {
        encryptedData = encrypt(
          JSON.stringify({
            ...modalValues,
            task: taskArray,
          })
        );
      }
    } else {
      encryptedData = encrypt(
        JSON.stringify({
          ...values,
          task: taskArray,
          serviceId: Object.keys(serviceObj),
        })
      );
    }

    formData.append("data", JSON.stringify(encryptedData));

    if (file) {
      formData.append("image", file);
    }

    if (modal) {
      dispatch(updateSubcategory([modalId, formData]));
      dispatch(hideModal());
    } else {
      dispatch(createSubcategory(formData));
      resetForm();
      setTaskArray([]);
      setServiceObj({});
      document.querySelector("#formFile").value = "";
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const btnStatus = (isValid, dirty) => {
    // checking if upload or edit

    if (!modal) {
      return !(
        isValid &&
        dirty &&
        taskArray.length > 0 &&
        Object.values(serviceObj).length > 0 &&
        !fileError &&
        file
      );
    }
    return false;
  };

  const renderCategoriesOption = () =>
    catogriesList.map((item) => {
      return (
        <MenuItem key={item._id} value={item._id}>
          {item.categoryName}
        </MenuItem>
      );
    });

  const onServiceClick = (id, name) => {
    setServiceObj({ ...serviceObj, [id]: name });
  };

  const renderServiceOption = () =>
    serviceList.map((item) => {
      return (
        <MenuItem
          onClick={() => onServiceClick(item._id, item.serviceName)}
          key={item._id}
          value={item._id}
        >
          {item.serviceName}
        </MenuItem>
      );
    });

  const handleDeleteService = (id) => {
    const serviceObjCopy = { ...serviceObj };
    delete serviceObjCopy[id];
    setServiceObj(serviceObjCopy);
  };

  const renderChipService = () => {
    const jsxArr = [];
    for (const key in serviceObj) {
      const elem = (
        <Chip
          className={classes.chip}
          key={`chip-${key}`}
          label={serviceObj[key]}
          onDelete={() => handleDeleteService(key)}
        />
      );
      jsxArr.push(elem);
    }
    return jsxArr;
  };

  const handleFileChange = (event) => {
    const fileArray =
      event.currentTarget.files[0] &&
      event.currentTarget.files[0].name.split(".");

    const fileExt = fileArray && fileArray[fileArray && fileArray.length - 1];
    if (fileExt === "png" || fileExt === "jpg") {
      setFile(event.currentTarget.files[0]);
      setFileError(false);
    } else {
      setFileError(true);
    }
  };

  const renderImage = () => {
    if (modal && formModalData.image) {
      return (
        <img
          className={classes.img}
          src={`${FileBaseUrl}${formModalData.image}`}
          alt={formModalData.categoryName}
        />
      );
    }
  };

  const renderServiceSelect = () => {
    if (!modal) {
      return (
        <FormControl
          variant="outlined"
          fullWidth
          color="primary"
          className={classes.formControl}
        >
          <InputLabel id="formSelect1">Service</InputLabel>
          <Select
            name="service"
            labelId="formSelectLabel1"
            id="formSelect1"
            label="Service"
            value=""
          >
            {renderServiceOption()}
          </Select>
        </FormControl>
      );
    }
    return null;
  };

  const renderForm = () => {
    return (
      <Card variant="outlined" color="primary" className={classes.root}>
        <CardHeader title="Add Subcategory" />
        <div className={classes.form}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <CardContent>
                  <TextField
                    error={touched.subCategoryName && !!errors.subCategoryName}
                    name="subCategoryName"
                    id="formSubCategoryName"
                    label="Sub Category"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subCategoryName}
                    helperText={
                      touched.subCategoryName ? errors.subCategoryName : false
                    }
                    variant="outlined"
                    fullWidth
                    color="primary"
                  />

                  <TextField
                    error={touched.description && !!errors.description}
                    name="description"
                    id="formDescription"
                    label="Description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    helperText={
                      touched.description ? errors.description : false
                    }
                    variant="outlined"
                    fullWidth
                    color="primary"
                  />

                  <FormControl
                    variant="outlined"
                    fullWidth
                    color="primary"
                    className={classes.formControl}
                    error={touched.categoryId && !!errors.categoryId}
                  >
                    <InputLabel id="formSelect">Category</InputLabel>
                    <Select
                      name="categoryId"
                      labelId="formSelectLabel"
                      id="formSelect"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.categoryId}
                      label="Category"
                    >
                      {renderCategoriesOption()}
                    </Select>
                    <FormHelperText>
                      {touched.categoryId ? errors.categoryId : false}
                    </FormHelperText>
                  </FormControl>

                  <ChipInput
                    modal={modal}
                    modaData={formModalData && formModalData.task}
                    data={taskArray}
                    setData={setTaskArray}
                  />

                  {renderServiceSelect()}
                  {renderChipService()}

                  <TextField
                    id="formFile"
                    type="file"
                    error={fileError}
                    variant="outlined"
                    helperText={`Allowed format jpg and png ${
                      fileError ? "(invalid file format)" : ""
                    }`}
                    onChange={handleFileChange}
                    fullWidth
                    color="primary"
                  />
                  {renderImage()}
                </CardContent>
                <CardActions className={classes.action}>
                  <Button
                    disabled={btnStatus(isValid, dirty)}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </CardActions>
              </form>
            )}
          </Formik>
        </div>
      </Card>
    );
  };

  if (modal) {
    return renderForm();
  }

  return (
    <Skeleton>
      {renderForm()}
      <Snackbar open={open} autoHideDuration={3900} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || "error"}>
          <p className="message">{message}</p>
        </Alert>
      </Snackbar>
      {loaderDisplay ? <LinearProgress className="loader" /> : false}
    </Skeleton>
  );
};

export default AddSubCategory;
