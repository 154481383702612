import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MdEdit, MdDelete } from "react-icons/md";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import Skeleton from "components/Skeleton";
import useTodoList from "customHooks/useTodosList";
import { getTodo, clearMessage } from "features/Todo/todoSlice";
import Modal from "Modal";
import { showModal } from "Modal/modalSlice";
import { encrypt } from "utils";
import DatePicker from "components/DatePicker";
import { isInBetween, getFormatedDate } from "utils";
import Popup from "components/ConfirmPopup";
import { showPopup } from "components/ConfirmPopup/popupSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
  container: {
    margin: "1rem",
  },
  button: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",

    "& > svg": {
      fontSize: "1.2rem",
    },
  },
});

const ViewAllTodos = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [fromDate, setFromDate] = useState(new Date("2020-01-01"));
  const [toDate, setToDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const modalStatus = useSelector((state) => state.modal.visibility);
  const popupDisplay = useSelector((state) => state.popup.visibility);

  const loaderDisplay = useSelector((state) => state.todos.loading);
  const message = useSelector((state) => state.todos.message);
  const severity = useSelector((state) => state.todos.severity);

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);

      setTimeout(() => {
        dispatch(clearMessage());
      }, 4100);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    setOpen(false);
  }, []);

  const todoList = useTodoList();

  const handleDelete = (id) => {
    dispatch(showPopup({ visibility: true, id }));
  };

  const handleEdit = (id) => {
    dispatch(showModal({ id }));
    dispatch(getTodo(encrypt(JSON.stringify({ id }))));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const renderRow = () => {
    return todoList
      .filter((item) => isInBetween(fromDate, toDate, item.createdAt))
      .map((item, index) => {
        return (
          <TableRow key={item._id}>
            <TableCell component="th" scope="row">
              {index + 1}
            </TableCell>
            <TableCell component="th" scope="row">
              {item.heading}
            </TableCell>

            <TableCell component="th" scope="row">
              {item.description}
            </TableCell>
            <TableCell component="th" scope="row">
              {item.type}
            </TableCell>
            <TableCell component="th" scope="row">
              {getFormatedDate(item.createdAt)}
            </TableCell>
            <TableCell align="right">
              <button
                onClick={() => handleEdit(item._id)}
                className={classes.button}
              >
                <MdEdit />
              </button>
            </TableCell>
            <TableCell align="right">
              <button
                onClick={() => handleDelete(item._id)}
                className={classes.button}
              >
                <MdDelete />
              </button>
            </TableCell>
          </TableRow>
        );
      });
  };

  const handleDateToChange = (date) => {
    setToDate(date);
  };

  const handleDateFromChange = (date) => {
    setFromDate(date);
  };

  return (
    <Skeleton>
      <DatePicker
        handleDateToChange={handleDateToChange}
        handleDateFromChange={handleDateFromChange}
        fromDate={fromDate}
        toDate={toDate}
      />
      <TableContainer className={classes.container} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Heading</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderRow()}</TableBody>
        </Table>
      </TableContainer>
      {modalStatus ? <Modal title="Edit Todo" type="todo" /> : ""}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || "success"}>
          <p className="message">{message}</p>
        </Alert>
      </Snackbar>
      {loaderDisplay ? <LinearProgress className="loader" /> : false}
      {popupDisplay ? <Popup parent="todos" /> : false}
    </Skeleton>
  );
};

export default ViewAllTodos;
